input, select, button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  box-sizing: border-box;
}

.checkout-page {
  background-color: #fff;
  color: #333;
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
}

.form-section {
  margin-bottom: 20px;
}

.form-section h2 {
  margin-bottom: 10px;
}

button {
  background-color: #00af1d;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

button:hover {
  background-color: #73ff57;
}
