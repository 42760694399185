@keyframes hop {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); 
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.hero-image-container {
  position: relative;
  width: 100%;
  height: 50vw;
  overflow: hidden;
}

.hero-video {
  margin-top: 40px;
  width: 90%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* Intro Section */
.intro-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 4rem 0rem;
  background-attachment: fixed;
  background-image: url('../webp/environment4.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 90px;
}



.intro-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.intro-content, .rabbit-image {
  position: relative;
  z-index: 1;
}

.rabbit-image {
  width: 40%;
  object-fit: contain;
  margin-bottom: 2rem;
  animation: hop 1s ease-in-out infinite;
  transition: transform 0.3s;
  cursor: pointer;
}

.rabbit-image:hover {
  transform: scale(1.1);
}


.come-in {
  opacity: 1; 
  transform: translateY(0);
}

.intro-content {
  background: rgba(250, 250, 250, 0.9);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  max-width: 60%;
  text-align: center;
}


.intro-content:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}
 

.intro-content h2 {
    color: #333;
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: normal;
    text-align: center;
  } 


.intro-content p {
  font-size: 2rem;
  line-height: 1.6; 
  color: #555555; 
  text-align: left;
  font-weight: normal;

}

.intro-content h2, .intro-content p {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  transform: scale(1);

}


/* Objective Section */
.objective-section {
background-color: #ffffff;
padding: 3rem 2rem;
margin: 2rem auto;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
border-radius: 10px;
max-width: 100%;
text-align: left;
}

.objective-section h2 {
color: #333;
font-size: 3rem;
margin-bottom: 1rem;
font-weight: normal;
}

.objective-section p {
color: #666;
font-size: 2rem;
line-height: 1.6;
margin-bottom: 1rem;
text-align: left;
}

.objective-section {
  background-color: #ffffff;
  padding: 3rem 2rem;
  margin: 2rem auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.objective-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.objective-content, .promo-code-container {
  flex: 1;
}

.promo-code-container {
  padding: 1rem;
  background-color: rgba(255, 250, 200, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.promo-content h3 {
  color: #d35400;
  margin-bottom: 1rem;
  font-size: 3rem;
}

.promo-content p {
  color: #333;
  font-size: 2rem;
  text-align: center;
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .objective-flex-container {
    flex-direction: column;
  }

  .promo-code-container {
    margin-top: 20px;
  }
}



/* Products Preview Section */
.products-preview-section h2 {
  color: #333;
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: normal;
}


.products-preview-section {
  padding: 20px;
  text-align: center;
}


.products-preview-container {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  justify-content: center;
}

.product-preview {
  flex: 0 1 calc(33.333% - 10px);
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; 
  text-align: center;
}

.product-preview:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

.product-preview img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1;
  max-height: 300px;
}

.product-preview-name,
.product-preview-price {
  margin: 5px 0;
  color: #333; 
}

.view-all-button {
  display: inline-block;
  font-size: 20px;
  padding: 10px 20px; 
  background-color: #4CAF50; 
  color: #fff; 
  text-decoration: none;
  font-weight: normal;
  border-radius: 5px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.view-all-button:hover {
  background-color: #367C39; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}



/* Environment Section */
  .environment-showcase-section {
    text-align: center;
    padding: 30px 20px;
  }
  
  .environment-showcase-section h2 {
    color: #333;
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: normal;
  }
  
  .environment-image {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    border-radius: 8px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin-bottom: 20px;
  }
  
  .environment-showcase-section p {
    font-size: 2rem;
    color: #555; 
    max-width: 1500px; 
    margin: 0 auto; 
    padding: 0 10px;
    text-align: left;
  }

  .explore-game-button {
    display: inline-block; 
    font-size: 20px;
    padding: 10px 25px; 
    background-color: #4CAF50; 
    color: #fff; 
    text-decoration: none; 
    font-weight: normal; 
    border-radius: 5px; 
    transition: background-color 0.3s, transform 0.2s; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    margin-top: 20px; 
    cursor: pointer; 
  }
  
  .explore-game-button:hover {
    background-color: #367C39; 
    transform: translateY(-2px); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); 
  }

  .slider-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 600px;
}

.environment-showcase-section .slick-slider {
    margin: 20px 0;
}

.environment-showcase-section .slick-dots li button:before {
    color: #fff; 
}

.environment-showcase-section .slick-prev:before,
.environment-showcase-section .slick-next:before {
    color: #333;  
}

/* CTA Section */
.cta-section {
  background-color: #eef2f3; 
  text-align: center; 
}

.cta-content h2 {
  color: #008f68; 
}



@media screen and (max-width: 768px) {
  .home-container {
    padding: .5rem;
  }

  .hero-video {
    width: 100%;
    margin-top: 0;
  }

  .hero-image-container {
    height: 30vh;
  }

  .intro-container {
    flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0px;
        padding: 1rem;
    }

    .rabbit-image {
      width: 40%;
      height: auto;
  }

  h2, .environment-showcase-section h2, .products-preview-section h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .products-preview-section h3{
    font-size: 1.2rem;
  }

  .product-preview-price{
    font-size: 1.2rem;
  }

  p, .objective-section p, .environment-showcase-section p {
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .intro-content {
    width: 60%;
    padding: 0;
    text-align: left;
}

.intro-content h2 {
  font-size: 2rem;
  margin: 0;
}

.intro-content p {
  font-size: 1.2rem;
}



  .products-preview-section h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .products-preview-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
  }

  .product-preview {
    width: 90%;
    margin: 0 auto;
}

  .product-preview img {
    height: auto;
    max-width: 100%;
  }

  .product-preview-name, .product-preview-price {
    font-size: 1.2rem;
  }


   /* Objective Section */
   .objective-section {
    padding: 0rem 1rem;
  }

  .objective-section .objective-content {
    padding: 1rem;
  }

  .objective-section h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .objective-section p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
  }

  .view-all-button, .explore-game-button {
    padding: 12px 24px;
    margin-top: 20px;
  }

  /* Environment Section */
  .environment-image {
    max-height: 250px;
  }

  .environment-showcase-section h2, .environment-showcase-section p {
    font-size: 1.2rem;
  }

  .slider-image {
    max-height: 300px;
}

.environment-showcase-section {
  padding: 20px 10px;
}
}
