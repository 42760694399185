.profile-container {
  display: flex;
  width: 100%;
}

.profile-sidebar {
  min-width: 200px;
  border-right: 1px solid #ccc;
  padding: 20px;
}

.profile-content {
  flex-grow: 1;
  padding: 20px;
}

.profile-sidebar button {
  display: block;
  background: none;
  border: none;
  text-align: left;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  color: black;
  font-size: 1rem;
}

.profile-sidebar button:hover {
  background-color: #f0f0f0;
  color: #333;
}

.profile-sidebar button.active {
  font-weight: bold;
  color: black;
}

  @media (max-width: 768px) {
    .profile-container {
      flex-direction: column;
    }
  
    .profile-sidebar button {
      padding: 10px;
      margin: 3px; 
      font-size: 16px;
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  
 
    .profile-content {
      padding: 20px;
    }
  }


@media (max-width: 480px) {

  
  .profile-content {
    padding: 15px 10px;
  }
}
