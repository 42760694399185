.animals-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px;
    background-color: #ffffff;
}

.welcome-section {
    text-align: left;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 20px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.welcome-section h2 {
    color: #333;
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: normal;
    text-align: center;
  }
  
  .welcome-section p {
    font-size: 2rem;
    color: #555; 
    max-width: 1500px; 
    margin: 0 auto; 
    padding: 0 20px;
  }
  
  .welcome-section p {
    text-align: left;
  }


.parallax-card.giraffe-card {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.parallax-card {
    width: 100%; 
    max-width: 1400px; 
    min-height: 90vh; 
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: left;
    margin: 40px 0;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.parallax-card::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1; 
}

.parallax-card:hover::before {
    opacity: 0.7;
}



.parallax-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; 
    max-width: 600px; 
    top: 20%;
    bottom: 20%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s, top 0.3s, bottom 0.3s;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.parallax-content:hover {
    opacity: 1;
    visibility: visible; 
}


.parallax-content h3,
.parallax-content p {
    margin: 10px 0; 
    font-size: 30px;
}

.parallax-card:hover .parallax-content {
    opacity: 1;
    visibility: visible;
    top: 10%; 
    bottom: 10%; 
}



.dark .parallax-content {
    background: rgba(0, 0, 0, 0.7); 
}

.light .parallax-content {
    background: rgba(255, 255, 255, 0.7); 
    color: black;
}

.image-overlay {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7; 
    z-index: 1; 
}


.parallax-content h3 {
    color: #333;
    margin: 0 0 10px;
    font-size: 100px;
}

.parallax-content p {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    margin: 0;
    text-align: left;

}

.modal-content {
    width: 80%; 
    height: auto;
    max-width: 800px;
    margin: 0 auto;
}

model-viewer {
    width: 100%;
    height: 60vh;
    max-height: 800px;
}

.close-button {
    background:  #4CAF50;
    border: 1px solid #367c39;
    color: white;
    padding: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 11;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: background-color 0.3s ease;
}

.close-button:hover {
    background: #367c39;
}


.view-3d-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .view-3d-button:hover {
    background-color: #367c39;
  }

  .tap-hint {
    display: none;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    z-index: 2;
}

body.modal-open .tap-hint {
    display: none;
  }

  .mobile-ar-info {
    font-size: 1.5rem;
    color: #555;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
}

.parallax-content.hidden {
    opacity: 0;
    visibility: hidden;
}
  

@media screen and (max-width: 768px) {
    .mobile-ar-info {
        display: none;
    }

    .tap-hint {
        display: block;
    }

    .parallax-card {
        background-attachment: scroll; 
        min-height: 300px; 
    }

    .parallax-content h3 {
        font-size: 24px;
    }

    .parallax-content p {
        font-size: 16px;
    }

    .parallax-content {
        max-width: 90%; 
        padding: 20px; 
        top: 10%; 
        bottom: 10%; 
    }

    .view-3d-button {
        padding: 12px 24px;
        font-size: 16px;
    }

    .welcome-section p {
        text-align: left;
    }

    .welcome-section h2 {
        font-size: 1.5rem;
    }

    .welcome-section h2 + p {
        font-size: 1.2rem;
    }

}




@media screen and (max-width: 480px) {
    .parallax-card {
        min-height: 300px; 
        min-width: 100px;
    }
}