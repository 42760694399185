.image-container.offscreen-left,
.text-container.offscreen-left {
  transform: translateX(-100vw);
  opacity: 0;
}

.image-container.offscreen-right,
.text-container.offscreen-right {
  transform: translateX(100vw);
  opacity: 0;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInFromRight 1s ease-out forwards;
}

  

  .gamepage-container h1 {
    font-size: 3.5rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: normal;
  }

  
  

  
  .image-text-container {
    opacity: 1;
  visibility: visible;
  transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    width: 100%;
  }

  .image-text-container:not(.slide-in) {
    opacity: 0;
  }

  .image-text-container.row-reverse {
    flex-direction: row-reverse;
  }

  .image-container, .text-container {
    width: 60%;
    margin: 20px;
  }


  
  .image-text-container.slide-in {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    animation: slideIn 1s forwards;
  }
  
  .image-container {
    width: 50%; 
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .game-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .text-container {
    width: 40%; 
    margin: 20px;
  }
  
  
  .game-image:hover {
    transform: scale(1.03); 
  }
  
  .image-description {
    color: #333;
    font-size: 1.9rem;
    line-height: 1.6;
    text-align: left;
  }
  
  .image-container:hover .image-description {
    transform: translateY(0); 
  }
  
  
  
  .modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 900px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative; 
  }
  
  .trailer-video {
    width: 100%; 
    max-height: 500px; 
    border-radius: 5px; 
  }
  
  .close-button {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close-button:hover,
  .close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
 
  
  .image-container {
    animation: slideInLeft 1s forwards;
  }
  
  .text-container {
    animation: slideInRight 1s forwards;
  }
  
  
  @media (max-width: 768px) {

    .image-text-container, .image-text-container.row-reverse {
      flex-direction: column;
    }

    .image-text-container {
      flex-direction: column;
    }
  
    .image-container, .text-container {
      width: 100%;
      margin: 10px auto;
    }
  
    .gamepage-container h1 {
      font-size: 2.5rem;
    }
  
    .image-description {
      font-size: 1.4rem;
    }
  }