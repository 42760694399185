.cart-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.cart-empty {
  text-align: center;
  padding: 5rem 0;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.cart-item-image {
  width: 200px;
  height: auto;
  margin-right: 2rem;
}

.cart-item-details {
  flex-grow: 1;
  font-size: 1.5rem;
  text-align: center;
}

.cart-item-price, .cart-item-quantity {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.remove-item-btn {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.remove-item-btn:hover {
  background-color: #ff0000;
}

.cart-summary {
  text-align: right;
  margin-top: 2rem;
}

.cart-total {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.checkout-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.2s ease;
}

.checkout-btn:hover {
  background-color: #45a049;
}

.discount-input {
  margin-right: 1rem;
  padding: 0.5rem;
}

.apply-discount-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.apply-discount-btn:hover {
  background-color: #0056b3;
}

/* Responsive styles for small devices */
@media (max-width: 768px) {
  .cart-container {
    padding: 1rem;
  }

  .cart-item {
    flex-wrap: wrap;
  }

  .cart-item-image {
    flex: 1 1 auto;
    max-width: 110px;
    margin-right: 1rem;
  }

  .cart-item-details {
    flex: 1 2 60%;
    font-size: 1rem;
  }

  .remove-item-btn, .apply-discount-btn, .checkout-btn, .discount-input {
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
  }

  

  .cart-summary {
    text-align: center;
    font-size: .5rem;
  }
}
