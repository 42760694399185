.contact-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  text-align: center;
}

.contact-container h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 3rem;
}

.contact-container p {
  font-size: 2rem;
  line-height: 1.6;
  color: #666;
}

.contact-social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.contact-social-link {
  font-size: 2.5rem;
  color: #09c457;
  transition: color 0.3s ease;
}

.contact-social-link:hover {
  color: #0056b3;
}
