.products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.likes-header {
  text-align: center;
  width: 100%; /* Ensure it spans the full width */
  margin-top: 20px; /* Adjust as needed */
  margin-bottom: 20px; /* Adjust as needed */
}

.product {
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.product-image {
  width: 100%;
  object-fit: cover;
  height: 200px;
}

.product-name {
  font-size: 1.2rem;
  margin: 10px 0;
}

.product-price {
  font-size: 1rem;
  margin-bottom: 10px;
}

.product-interaction {
  padding: 10px;
  text-align: center;
}

.quick-view-button {
  padding: 5px 10px;
  margin-bottom: 10px;
}