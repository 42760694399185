.site-footer {
  background-color: #333;
  color: white;
  padding: 40px 0;
  text-align: center;
  border-top: 5px solid #4CAF50; 
}

.footer-content p {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  color: white;
}

.site-footer h3 {
  margin-bottom: 20px;
}

.site-footer p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.footer-links li {
  display: inline-block;
  margin-right: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #4CAF50; 
}



.footer-bottom p {
  font-size: 0.8rem;
  margin-top: 20px;
  color: white;
}

.footer-social-media a {
  margin: 0 10px;
  color: white; 
}

.footer-social-media a:hover {
  color: #4CAF50; 
}


@media (max-width: 768px) {
  .footer-links li {
    display: block;
    margin-bottom: 10px;
  }
}
