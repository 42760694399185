.auth-container1 {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.password-reset-message {
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.password-reset-message.info {
  color: #31708f;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
}

.password-reset-message.error {
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
}

.auth-form input,
.auth-form button {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.auth-form input {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.reset-email-button {
  background-color: #367C39;
  color: white;
  cursor: pointer;
  border: none;
  display: block;
  width: 100%;
}

.reset-email-button:hover {
  background-color: #285e28;
}
