.ecommerce-container h1 {
  color: #333;
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: normal;
}

.ecommerce-container h1 + p {
  font-size: 2rem;
  color: #555; 
  max-width: 1500px; 
  margin: 0 auto; 
  padding: 0 20px;
}



.ecommerce-container {
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.filter-container {
  margin-bottom: 30px;
  text-align: center;
}

.filter-container a {
  display: inline-block;
  margin: 0 15px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #00743a;
  color: white;
  border-radius: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.filter-container a:hover, .filter-container a:focus {
  background-color: #00b35a;
  transform: translateY(-2px);
  outline: none;
}

/* Shop Container & Product Styles */
.shop-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.shop-container p{
 color: black;
 font-size: 20px;
}

.product {
  flex: 0 1 calc(33.333% - (2 * 20px + 2 * 10px) / 3);
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
  transition: transform 0.3s ease;
}

.product:hover {
  transform: scale(1.03);
  border-color: #4CAF50;
  background-color: #f9f9f9;
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1;
  max-height: none;
  border-radius: 8px;
  transition: transform 0.5s ease;
}

.product:hover .product-image {
  transform: scale(1.1);
}

.product-interaction {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product:hover .product-interaction {
  display: block; 
}

.product-name,
.product-price {
  margin: 10px 0;
  font-weight: 500;
}


@media screen and (min-width: 768px) {
  .shop-container {
    gap: 20px;
  }

  .product {
    flex: 0 1 calc((100% - 80px) / 3);
    margin: 10px;
  }

  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-width: 300px;
    min-height: 300px;
}
}


  @media (max-width: 480px) {
    .shop-container {
      justify-content: flex-start;
      gap: 40px;
    }
  
    .shop-container a {
      flex: 1 0 calc(40% - 10px);
      text-align: center;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
  
    .shop-container .product {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0;
    }
  
    .product-image {
      width: 100%;
      height: auto;
    }

    .product-image {
      object-fit: contain;
  }
  

  .filter-container {
    flex-direction: column;
    align-items: center;
  }

  

  .filter-container a {
    margin: 5px;
    padding: 8px 16px;
    font-size: 0.9rem;
    width: auto;
    text-align: center;
  }

  .product-name,
  .product-price {
    font-size: 0.85rem;
  }

  .ecommerce-container h1 + p {
    text-align: left;
    font-size: 1.2rem;
    color: #555; 
    max-width: 1500px; 
    margin: 0 auto; 
    padding: 0 20px;
  }
}