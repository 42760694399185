.full-page-container {
  background-image: url('../images/environment.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.auth-container {
  width: 400px;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  text-align: center;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth-form input,
.auth-form button {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.auth-form button {
  background-color: #367C39;
  color: white;
  cursor: pointer;
  border: none;
}

.auth-form button:hover {
  background-color: #367C39;
}

.auth-form label {
  font-weight: bold;
}

.auth-input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 1rem;
}

.password-input-container {
  position: relative;
}

.password-field {
  display: flex;
  align-items: center;
  position: relative;
}

.password-toggle-icon {
  margin-left: -30px;
  cursor: pointer;
}

.forgot-password-button, .register-link, .login-link {
  background: none;
  color: blue;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.password-toggle-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-link {
  color: #0645AD;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0.5rem;
  display: block;
}
