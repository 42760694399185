.product-details-container {
  display: flex;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
  background-color: #f5f5f5;
  min-height: 95vh;
  margin: 0;
  width: 100%;
}

.product-details-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  background: #ffffff;
  padding: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 1rem auto;
}

.product-details-image-container {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.product-details-info {
  flex: 0 0 50%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.product-details-info h1,
.product-details-info p,
.product-details-info .product-price {
  font-size: 1.9rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1rem;
}

.product-details-image {
  width: 100%;
  max-height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.add-to-cart-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #367c39;
}

.like-btn {
  background-color: #4CAF50;
  color: rgb(255, 255, 255);
  border: 1px solid #cccccc;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 1rem;
}

.like-btn:hover {
  background-color: #034e00;
}

.like-btn.liked {
  background-color: #034e00;
  color: white;
  border-color: #034e00;
}

.like-btn.liked:hover {
  background-color: #4CAF50;
}



@media (max-width: 768px) {

  .product-details-container {
    padding-top: 2vh;
    padding-bottom: 2vh;
    min-height: auto;
  }

  .product-details-card {
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
  }

  .product-details-image-container {
    width: 100%;
    max-height: 300px;
    min-height: auto;
    padding: 0;
  }



  .product-details-info h1,
  .product-details-info p,
  .product-details-info .product-price {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .product-quantity-size,
  .quantity-input,
  .size-select,
  .add-to-cart-btn,
  .like-btn {
    width: 100%;
    max-width: none;
    margin-top: 1rem;
  }

  .quantity-input,
  .size-select {
    font-size: 1rem;
  }

  .add-to-cart-btn,
  .like-btn {
    padding: 12px;
  }
}

