.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .brand-logo img {
  width: 55px;
  height: auto;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fcfcfc;
  font-size: 2rem;

}

.navbar-button {
  background-color: #F8F9FA;
  color: rgb(151, 151, 151);
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.navbar-button:hover {
  background-color: #F8F9FA;
}




.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-links a,
.cart-login-section a,
.logout-button {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 1.8rem;

}

.nav-links a:hover,
.cart-login-section a:hover {
  background-color: #f0f0f0;
}



.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .left-section,
.navbar .right-section {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .home-icon {
  width: 55px;
  height: auto;
}

.navbar .nav-links {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  font-size: 16px;
}

.navbar .nav-links a {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.navbar .nav-links a:hover {
  background-color: #e2e6ea;
  color: #000;
}

.navbar .right-section {
  display: flex;
  justify-content: flex-end;
}

.navbar .right-section .cart-icon {
  font-size: 1.5rem;
  color: #333;
}

.cart-icon {
  position: relative;
  font-size: 1.5rem;
  display: inline-block;
}



.cart-count {
  padding: 2px 6px;
  position: absolute;
  border-radius: 50%;
  top: -10px; 
  right: -15px; 
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 50%;
  width: 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}




.nav-expanded {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #333;
}

.dropdown-content {
  display: block;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: center;
}

.dropdown-content a {
  display: block;
  padding: 8px 16px;
  color: #333;
  text-decoration: none;
}

.profile-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.profile-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 30px;
}


.logout-success-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #444;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
}


/* Media query for smaller screens */
@media (max-width: 768px) {
  .hamburger {
    display: block;
    position: absolute;
    left: -140px; 
    top: -17px;
    color: #333;
  }

  .navbar .home-icon {
    display: none;

  }

  .navbar .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #333;
    z-index: 2;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    padding-top: 0;
  }

  .navbar .nav-links.nav-expanded {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 0rem);
    left: 0;
    right: 0;
    background-color: rgb(102, 102, 102);
    z-index: 2;
    width: 100%;
  }

  .navbar .nav-links.nav-expanded a {
    color: rgb(255, 255, 255);
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #e2e6ea;
    display: block;
    width: 100%;
    box-sizing: border-box;
    min-height: 4rem;
    font-size: 1.2rem;
    pointer-events: auto;
  }

  .navbar .nav-links a {
    color: #333;
    text-align: center;
    padding: 1rem;
    pointer-events: auto;
  }

  .navbar {
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    z-index: 100;
  }

  .navbar .right-section {
    position: relative;
    right: 0;
    margin-top: 0;
  }

  .navbar .left-section {
    display: none;
  }

  .profile-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

}