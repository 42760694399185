@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');

body, h1, h2, h3, p {
  font-family: 'Chewy'; 
  font-display: swap; 

}



a {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
}

a:hover {
    color: #FD914B;
}

.full-height-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
